"use client";

import { createUserInstance, loggedInUserHandler, saveUserAction } from "@/lib/features/user/userSlice";
import { signIn } from "next-auth/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
const CustomSignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const result = await signIn("credentials", {
      // redirect: false,
      callbackUrl: "/",
      username,
      password
    });
    dispatch(createUserInstance({
      email: "admin@factagora.com",
      name: "admin"
    }));
    if (result?.error) {
      console.error(result.error);
    } else {
      console.log("Signed in successfully");
    }
  };
  return <div style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    padding: "20px",
    backgroundColor: "#f0f2f5"
  }} data-sentry-component="CustomSignIn" data-sentry-source-file="page.tsx">
      <h1 style={{
      marginBottom: "20px"
    }}>Welcome to DeepVerify.ai!</h1>
      <form onSubmit={handleSubmit} style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      maxWidth: "400px",
      padding: "20px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"
    }}>
        <div style={{
        marginBottom: "15px",
        width: "100%"
      }}>
          <label style={{
          display: "block",
          marginBottom: "5px"
        }}>
            Username:
            <input type="text" value={username} onChange={e => setUsername(e.target.value)} style={{
            width: "100%",
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #ccc"
          }} />
          </label>
        </div>
        <div style={{
        marginBottom: "20px",
        width: "100%"
      }}>
          <label style={{
          display: "block",
          marginBottom: "5px"
        }}>
            Password:
            <input type="password" value={password} onChange={e => setPassword(e.target.value)} style={{
            width: "100%",
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #ccc"
          }} />
          </label>
        </div>
        <button type="submit" style={{
        width: "100%",
        padding: "10px",
        borderRadius: "4px",
        border: "none",
        backgroundColor: "#007bff",
        color: "#fff",
        cursor: "pointer",
        marginBottom: "10px"
      }}>
          Sign in with Credentials
        </button>
      </form>
      <button onClick={() => signIn("azure-ad")} style={{
      width: "100%",
      maxWidth: "400px",
      padding: "10px",
      borderRadius: "4px",
      border: "none",
      backgroundColor: "#007bff",
      color: "#fff",
      cursor: "pointer",
      marginBottom: "10px"
    }}>
        Sign in with Azure AD
      </button>
      <button onClick={() => window.open("https://ineffable-life-306152.framer.app/", "_blank")} style={{
      width: "100%",
      maxWidth: "400px",
      padding: "10px",
      borderRadius: "4px",
      border: "none",
      backgroundColor: "#28a745",
      color: "#fff",
      cursor: "pointer"
    }}>
        Join Wait List
      </button>
    </div>;
};
export default CustomSignIn;